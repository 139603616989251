import React, { useEffect, useRef, useState } from 'react';

interface CountUpProps {
  value: string;
  duration: number;
}

const CountUp: React.FC<CountUpProps> = ({ value, duration }) => {
  const [animatedText, setAnimatedText] = useState<string>('');
  const targetRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    let startTimestamp: number;
    let animationFrameId: number;

    const animateCount = (timestamp: number) => {
      if (!startTimestamp) startTimestamp = timestamp;
    
      const progress = Math.min(1, (timestamp - startTimestamp) / duration);
      const parts = value.split(/(\d+[\.,]?\d*)/);
    
      let animatedText = '';
    
      for (let i = 0; i < parts.length; i++) {
        if (/\d+[\.,]?\d*/.test(parts[i])) {
          // if number with or without decimal, animation
          const numberString = parts[i].replace(',', ''); // replacing comma by dot for thousands
          const numberValue = parseFloat(numberString);
          const animatedValue = progress * numberValue;
    
          // formate the animated number with the correct thing, comma or dot
          const formattedValue = animatedValue.toLocaleString('en-US', {
            minimumFractionDigits: numberString.includes('.') ? 1 : 0, // keep decimal if ok
            maximumFractionDigits: numberString.includes('.') ? 2 : 0, // limit decimal if too much
          });
    
          animatedText += formattedValue;
        } else {
          // otherwise add like this
          animatedText += parts[i];
        }
      }
    
      setAnimatedText(animatedText);
    
      if (progress < 1) {
        animationFrameId = requestAnimationFrame(animateCount);
      }
    };

    const handleIntersect = (entries: IntersectionObserverEntry[]) => {
      const entry = entries[0];
      if (entry.isIntersecting) {
        // Element is now visible, start the animation
        animationFrameId = requestAnimationFrame(animateCount);
      }
    };

    const observer = new IntersectionObserver(handleIntersect, { threshold: 0.5 });

    if (targetRef.current) {
      observer.observe(targetRef.current);
    }

    return () => {
      observer.disconnect();
      cancelAnimationFrame(animationFrameId);
    };
  }, [value, duration]);

  return <div ref={targetRef}>{animatedText}</div>;
};

export default CountUp;
