/** @jsx jsx */

import * as React from "react";
import {
  ComponentParams,
  ComponentRendering,
} from "@sitecore-jss/sitecore-jss-react";
import { jsx } from "@emotion/react";
import Tag from "../../core/components/Tag/tag.component";
import { SitecoreGenericTextField } from "../../interfaces";

export interface MeetOurExpertsTagProps {
  fields: any;
  params?: ComponentParams;
  rendering: ComponentRendering;
}

export const MeetOurExpertsTag: React.FunctionComponent<MeetOurExpertsTagProps> =
  (props: MeetOurExpertsTagProps): React.ReactElement => {
    const { fields } = props;

    return (
      <Tag
        label={(fields && (fields[`tag`] as SitecoreGenericTextField)).value}
      />
    );
  };
