/** @jsx jsx */

import * as React from "react";
import { Icon } from "@bdl-cmn-shared-packages-npm/design-system";
import {
  Text,
  ComponentParams,
  ComponentRendering,
  Placeholder,
} from "@sitecore-jss/sitecore-jss-react";
import { breakpointMax } from "../../style";
import { css, jsx } from "@emotion/react";
import { useState } from "react";
import { SitecoreGenericTextField } from "../../interfaces";
import AnchorContext from "../../core/components/Anchor/AnchorContext";

export interface MeetOurExpertsSectionProps {
  fields: any;
  params?: ComponentParams;
  rendering: ComponentRendering;
}

const top = css`
  display: flex;
  justify-content: space-between;
`;
const icon = css`
  color: #22aa5f;
  font-size: 36px;
  margin-top: -6px;
  line-height: 36px;
  @media (max-width: ${breakpointMax}) {
    font-size: 20px;
  }
`;
const title = css`
  color: #354450;
  font-size: 20px;
  font-weight: 800;
  @media (max-width: ${breakpointMax}) {
    color: #354450;
    font-size: 16px;
    font-weight: 700;
  }
`;
const defaultblocStyle = {
  background: "#ffffff",
  padding: 42,
  border: "solid 1px #d9e1e2",
  borderRadius: 8,
  marginBottom: 40,
  transition: "all 0.2s ease-in-out",
  cursor: "pointer",
  width: "100%",
};

export const MeetOurExpertsSection: React.FunctionComponent<MeetOurExpertsSectionProps> =
  (props: MeetOurExpertsSectionProps): React.ReactElement => {
    const { fields, rendering } = props;
    const [expend, setExpend] = useState(-1);
    const [registeredAnchorIds, setRegisteredAnchorIds] = useState<string[]>([]);

    const getBlocStyle = (i: number) => {
      return i === expend
        ? {
            ...defaultblocStyle,
          }
        : {
            ...defaultblocStyle,
            filter: "drop-shadow(0px 5px 10px rgba(0, 0, 0, 0.2))",
          };
    };

    const registerAnchorId = React.useCallback((id: string) => {
        if (!registeredAnchorIds.includes(id)) {
          setRegisteredAnchorIds((prevIds) => [...prevIds, id]);
        }
      },
      [registeredAnchorIds]
    );

    React.useEffect(() => {
      const currentHash = window.location.hash.replace("#", "");
      const index = registeredAnchorIds.findIndex((id) => id === currentHash);

      if (currentHash !== "") {
        if (index !== -1) {
          setExpend(-1); // Set the section index to expand based on the matching anchor ID

          setTimeout(() => {
            const element = document.getElementById(currentHash);
            if (element) {
              element.scrollIntoView({ behavior: "smooth" });
            }
          }, 0);
        } else {
          return;
        }
      }
      if (currentHash === "") {
        setExpend(0);
        window.scrollTo(0, 0);
      }
    }, [registeredAnchorIds]);

    const sectionIndex = [{}];

    return (
      <>
        <AnchorContext.Provider value={{ registerAnchorId }}>
          {sectionIndex.map((bloc: any, i: number) => (
            <div
              key={i}
              style={getBlocStyle(i)}
              css={css`
                @media (max-width: ${breakpointMax}) {
                  padding: 20px !important;
                  margin-bottom: 16px !important;
                  flex-direction: column;
                }
              `}
            >
              <div
                css={top}
                onClick={() => {
                  setExpend(i === expend ? -1 : i);
                }}
              >
                <div css={title}>
                  <Text field={fields[`title`] as SitecoreGenericTextField} />
                </div>
                <div
                  css={icon}
                  onClick={() => setExpend(i === expend ? -1 : i)}
                >
                  <Icon icon={expend === i ? "chevron-down" : "chevron-up"} />
                </div>
              </div>
              <div>
                {expend !== i && (
                  <Placeholder name="jss-expert-cards" rendering={rendering} />
                )}
              </div>
            </div>
          ))}
        </AnchorContext.Provider>
      </>
    );
  };
